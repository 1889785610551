import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PubSub, PubsubEvents } from 'Services/core__services';
import { ChatStyle } from './core__style';
import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';
import { getCookie } from 'Services/cookie/core__cookies';
import FSBCustomerSession from 'Services/session/models/core__session.models.fsb';
import useFSBSession from 'Services/hooks/core__useFSBSession';

const hasInitialToken =
  !!FSBCustomerSession.accessToken ||
  !!FSBCustomerSession.customerOBJ?.accessToken;

const KMLiveChatApplication = ({ appConfig }) => {
  const customerData = useFSBSession();

  // we cant load the SF livechat script multiple times
  useEffect(() => {
    const isLoggedIn = !!customerData;

    // we load it once on page load (for not logged in customers)
    if (!hasInitialToken && !isLoggedIn) createChat();

    // if they just logged in, we need to refresh the page to clear out / delete previous live chat init
    if (!hasInitialToken && isLoggedIn) {
      setTimeout(() => window.location.reload(), 1000);
    }

    // or we load it once on page load (for logged in customers)
    if (hasInitialToken && isLoggedIn && customerData) {
      setUserFieldFromData(customerData);
      createChat();
      setTimeout(showEswFab, 1000);
    }
  }, [customerData]);

  // capture values from custodian
  const config = {
    primaryDomainUrl: appConfig?.primaryDomainUrl,
    secondaryDomainUrl: appConfig?.secondaryDomainUrl,
    appCdnUrl: appConfig?.appCdnUrl,
    inactivityMinuts: parseInt(appConfig?.inactivityMinuts),
    chatId: appConfig?.chatId,
  };

  const [userField, setUserField] = useState({
    FirstName: '',
    LastName: '',
    Email: '',
    Subject: '',
    Phone: '',
  });

  const setUserFieldFromData = data => {
    window.sessionStorage.setItem(
      GLOBAL_CONSTANTS.USER_DATA,
      JSON.stringify({
        ...userField,
        customerId: data?.customerId,
        FirstName: data?.firstName,
        LastName: data?.lastName,
        Email: data?.email,
        Phone: data?.mobileNumber,
      })
    );
    setUserField({
      ...userField,
      FirstName: data?.firstName,
      LastName: data?.lastName,
      Email: data?.email,
    });
  };

  const showEswFab = () => {
    const chatBox = document.querySelector('#esw-fab');
    PubSub.listen(PubsubEvents.TOGGLE_HELP_BUTTON, data => {
      if (data.hitting) {
        chatBox?.classList.add('show');
        window.embedded_svc?.menu?.openChannelMenu();
      }
    });
    if (appConfig.show) {
      chatBox?.classList.add('show');
    }
  };

  // setup the esw-fab
  useEffect(() => {
    const userToken = getCookie('AppSession');
    if (!userToken) {
      window.sessionStorage.removeItem(GLOBAL_CONSTANTS.USER_DATA);
    }
    window.onload = showEswFab;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //handle inactivity period
  useEffect(() => {
    function inactivityTime(minuts) {
      let time;
      window.onload = resetTime;
      window.onclick = resetTime;
      window.onkeypress = resetTime;
      window.ontouchstart = resetTime;
      window.onmousemove = resetTime;
      window.onmousedown = resetTime;
      window.addEventListener('scroll', resetTime, true);

      function resetTime() {
        clearTimeout(time);
        time = setTimeout(createChat, 1000 * 60 * minuts);
      }
    }
    if (config.inactivityMinuts) {
      inactivityTime(config.inactivityMinuts);
    }
  });

  const createChat = () => {
    const initESW = function (gslbBaseURL) {
      window.embedded_svc.menu.settings.displayHelpButton = true;
      window.embedded_svc.menu.settings.Fanguage = 'en';
      window.embedded_svc.menu.settings.prepopulatedPrechatFields = userField;

      window.embedded_svc.menu.settings.enabledFeatures = ['LiveAgent'];
      window.embedded_svc.menu.settings.entryFeature = 'LiveAgent';
      // let intervalId = null;
      // function myCallback() {
      //   const textBox = document.getElementsByClassName('chasitorInputWrapper');
      //   if (textBox.length !== 0) {
      //     clearInterval(intervalId);
      //     textBox[0].style.display = 'none';
      //   }
      // }
      // window.embedded_svc.menu.addEventHandler(
      //   'onChatRequestSuccess',
      //   function () {
      //     intervalId = setInterval(myCallback, 500);
      //   }
      // );
      // window.embedded_svc.menu.addEventHandler(
      //   'onChatTransferSuccessful',
      //   function () {
      //     const textBox = document.getElementsByClassName(
      //       'chasitorInputWrapper'
      //     );
      //     textBox[0].style.display = 'block';
      //   }
      // );
      window.embedded_svc.menu.init(
        config.primaryDomainUrl,
        config.secondaryDomainUrl,
        gslbBaseURL,
        config.chatId,
        'Contact_Us_ZAF',
        {
          pageName: 'customSnippetSettingsZAF',
        }
      );
    };

    if (!document.getElementById('salesforce-livechat-script')) {
      const s = document.createElement('script');
      s.setAttribute('id', 'salesforce-livechat-script');
      s.setAttribute('src', config.appCdnUrl);

      s.onload = function () {
        initESW(null);
      };
      document.body.appendChild(s);
    } else {
      initESW('https://service.force.com');
    }
  };

  return (
    <>
      <ChatStyle />
    </>
  );
};

KMLiveChatApplication.propTypes = {
  appConfig: PropTypes.object,
};

export default KMLiveChatApplication;
