import { BREAKPOINT_M, BREAKPOINT_S } from 'UI/globals/breakpoints';
import { createGlobalStyle } from 'styled-components';

export const ChatStyle = createGlobalStyle`
.modalContainer.sidebarMinimized {
  height: 0;
}
#esw-fab {
  visibility: hidden;
}
#esw-fab.show {
  visibility: initial;
}
.chatHeaderBranding {
  width: 100%;
}
label {
  margin-left: 4px;
}
.start-button {
  background: #E30613 !important;
  color: white !important;
  &:hover {
    background: white !important;
    color: #E30613 !important;
    border: 1px solid #E30613 !important;
  }
}
.sidebarHeader[embeddedService-chatHeader_chatHeader] {
  height: 56px;
  padding: 5px 5px 5px 28px;
}
.embedded-service {
  font-family: "Roboto Regular", sans-serif !important;
  font-size: 14px !important;
  #esw-channelmenu_header {
    &.showAnimation {
      background-color: #111fa3;
      color: white;
      padding: 10px 20px;
      min-height: 50px;
    }
  }
  .channelmenu_header {
    & > h2 {
      margin-bottom: 0;
      font-size: 14px;
    }
    & > p {
      margin: 0;
      font-size: 14px;
    }
  }
  #esw-fab {
    &.showAnimation {
      background-image: url('https://betking--uat.sandbox.my.salesforce-sites.com/newlivechat/resource/helpButton');
      background-repeat: no-repeat;
      padding: 0;
      background-color: transparent;
      box-shadow: 0 0 0 0;
      background-size: contain;
      top: calc(50% - 38px);
      height: 78px;
      width: 30px;
      border-radius: 0;
      left: 0px;
      overflow: inital;
      &:focus {
        border-radius: 0;
        background-color: transparent;
      }
      &:hover {
        border-radius: 0;
        background-color: transparent;
      }
    }
  }
  #esw-menu-opened-fab-icon {
    &.icon_fab_container {
      margin-top: -166px;
      position: absolute;
      width: 40px;
      height: 30px;
      right: -330px;
      background: #111fa3;
      border-radius: 50%;
    }
  }
  & > #esw-modaloverlay {
    &.isMaximized {
      z-index: 2;
      z-index: 2;
      & + div#esw-container {
        & > button {
          &.fab {
            z-index: 13;
            z-index: 13;
          }
        }
        & > div {
          &.channelmenu {
            z-index: 12;
            z-index: 12;
          }
        }
      }
    }
  }
  #esw-menu-closed-fab-icon {
    &.icon_fab_container {
      display: none;
    }
  }
  #esw-icon-question {
    &.esw-default_icon {
      display: none;
    }
  }
  .channelmenu {
    visibility: visible;
    position: fixed;
    left: 30px;
    display: block !important;
    top: calc(50% - 150px);
    height: 300px;
    margin-right: 8px;
  }
  .channelmenu_ctas {
    & > li {
      margin-left: 0;
    }
  }
}
.embeddedServiceSidebar {
  .sidebarBody {
    position: relative;
    margin-top: -10px;
    max-width: 100vw;
  }
  &.layout-float {
    .dockableContainer {
      left: 7px !important;
      display: flex !important;
      align-items: flex-end !important;
      padding: 0px !important;
      gap: 8px !important;
      width: 360px !important;
      height: 640px !important;
    }
  }
  &.sidebarMaximized {
    &.layout-float {
      .minimizedContainer {
        display: none;
      }
    }
  }
}
.embeddedservice-chat-header lightning-icon, embeddedservice-chat-header svg.slds-icon-text-default {
  display: none;
}
.minimizeButton[lwc-56vbshdpqil] {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" class="slds-icon slds-icon-text-default slds-icon_x-small" focusable="false" data-key="minimize_window" aria-hidden="true" viewBox="0 0 52 52" part="icon" style="fill: white;"%3E%3Cg%3E%3Cpath d="M50 48.5c0 .8-.7 1.5-1.5 1.5h-45c-.8 0-1.5-.7-1.5-1.5v-3c0-.8.7-1.5 1.5-1.5h45c.8 0 1.5.7 1.5 1.5v3z"%3E%3C/path%3E%3C/g%3E%3C/svg%3E');
  &:hover[lwc-56vbshdpqil]:before {
    right: 0px;
    top: 0px;
  }
}
.closeButton[lwc-56vbshdpqil] {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" class="slds-icon slds-icon-text-default slds-icon_x-small" focusable="false" data-key="close" aria-hidden="true" viewBox="0 0 52 52" part="icon" style="fill: white;"%3E%3Cg%3E%3Cpath d="M31 25.4l13-13.1c.6-.6.6-1.5 0-2.1l-2-2.1c-.6-.6-1.5-.6-2.1 0L26.8 21.2c-.4.4-1 .4-1.4 0L12.3 8c-.6-.6-1.5-.6-2.1 0l-2.1 2.1c-.6.6-.6 1.5 0 2.1l13.1 13.1c.4.4.4 1 0 1.4L8 39.9c-.6.6-.6 1.5 0 2.1l2.1 2.1c.6.6 1.5.6 2.1 0L25.3 31c.4-.4 1-.4 1.4 0l13.1 13.1c.6.6 1.5.6 2.1 0L44 42c.6-.6.6-1.5 0-2.1L31 26.8c-.4-.4-.4-1 0-1.4z"%3E%3C/path%3E%3C/g%3E%3C/svg%3E');
  &:hover[lwc-56vbshdpqil]:before {
    right: 0px;
    top: 0px;
  }
}
@media {
  only {
    screen {
      and {
        (max-width {
          &: {
            47 {
              &.9375em) {
                and {
                  (orientation {
                    &: {
                      portrait) {
                        .embedded-service #esw-menu-opened-fab-icon.icon_fab_container {
                left: calc(100vw - 50px);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
} {
  @media {
    only {
      screen {
        and {
          (max-width {
            &: {
              35 {
                &.5em) {
                  and {
                    (orientation {
                      &: {
                        portrait) {
                          .embedded-service #esw-menu-opened-fab-icon.icon_fab_container {
                left: calc(100vw - 50px);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .embedded-service {
    & > #esw-modaloverlay {
      &.isMaximized {
        z-index: 2;
      }
    }
  }
}
.embeddedServiceSidebarMinimizedFabDefaultUI {
  background-size: contain;
  max-height: 100%;
  background-color: transparent;
  background-image: url('https://betking--uat.sandbox.my.salesforce-sites.com/newlivechat/resource/chatMsg');
  background-repeat: no-repeat;
  padding: 0;
  border-radius: 0;
  border-style: none;
  box-shadow: 0 0 0 0;
  left: 0px;
  top: calc(50% - 32px/2 + 35.2px);
  height: 38px;
  width: 32px;
  overflow: initial;
  button {
    &.fab {
      display: none;
      &:hover {
        background-color: transparent;
      }
      &:focus {
        background-color: transparent;
      }
    }
  }
  .embeddedServiceIcon {
    display: none;
  }
  .content {
    &:focus {
      background-color: transparent;
    }
  }
}
body button:hover, body .btn:hover, body .btnCancel:hover, body .menuButton .menuButtonButton:hover {
  background-position: right 0px;
}
.embedded-service .fab, .embedded-service .fab:hover, .embedded-service .fab:focus, .embedded-service .fab:active {
  background-color: transparent;
}
.embeddedServiceSidebarMinimizedFabDefaultUI.default .notification.showMinimizedNotification, .embeddedServiceSidebarMinimizedFabDefaultUI.alert .notification.showMinimizedNotification, .embeddedServiceSidebarMinimizedFabDefaultUI.info .notification.showMinimizedNotification {
  animation: fadein .5s;
  display: none;
}
[embeddedservice-chatheader_chatheader-host] {
  background-color: #111fa3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 360px;
  height: 56px;
  background: #111fa3;
}
.embedded-service .fab:hover:before, .embedded-service .fab:focus:before {
  width: 0;
}
.embeddedServiceSidebarMinimizedFabDefaultUI.newMessage, .embeddedServiceSidebarMinimizedFabDefaultUI.idleTimeout {
  background-image: url('https://betking--uat.sandbox.my.salesforce-sites.com/newlivechat/resource/chatMsg');
  background-repeat: no-repeat;
  background-color: transparent;
  padding: 0;
  background-size: contain;
  max-height: 100%;
  padding: 0;
  border-radius: 0;
  border-style: none;
  box-shadow: 0 0 0 0;
  left: 0px;
  top: calc(50% - 32px/2 + 35.2px);
  height: 38px;
  width: 32px;
  overflow: initial;
}

@media only screen and (max-width: 47.9375em) and (orientation: portrait) {
  .embedded-service {
    #esw-menu-opened-fab-icon.icon_fab_container {
      left: calc(100vw - 50px);
    }
    >#esw-modaloverlay.isMaximized+div#esw-container {
      >button.fab {
        z-index: 13;
      }
      >div.channelmenu {
        z-index: 12;
      }
    }
    >#esw-modaloverlay.isMaximized {
      z-index: 2;
    }
  }
}
@media only screen and (max-width: 35.5em) and (orientation: portrait) {
  .embedded-service {
    #esw-menu-opened-fab-icon.icon_fab_container {
      left: calc(100vw - 50px);
    }
    >#esw-modaloverlay.isMaximized+div#esw-container {
      >button.fab {
        z-index: 13;
      }
      >div.channelmenu {
        z-index: 12;
      }
    }
    >#esw-modaloverlay.isMaximized {
      z-index: 2;
    }
  }
}
@media screen and (max-width: ${BREAKPOINT_M}) {
  .embedded-service {
    #esw-fab.showAnimation {
      left: auto;
      top: auto;
      bottom: 0;
      transform: rotate(90deg) !important;
      right: 78px;
      border-radius: 9px 0px 0px 9px;
      background-color: #111fa3;
      &:hover {
        border-radius: 9px 0px 0px 9px;
        background-color: #111fa3;
      }
    }
  }
}
@media screen and (max-width: ${BREAKPOINT_S}) {
  .embeddedServiceSidebar {
    &.layout-float {
      .dockableContainer {
        height: inherit !important;
        margin: 0px auto !important;
      }
    }
  }
}
`;
